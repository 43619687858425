<template>
  <div v-if="owners.length === 1" class="flex items-center relative">
    <div class="w-8 h-8 rounded-full parula__bg-white">
      <img v-if="!owners[0]" :src="pic" alt="owner" class="img__shape" />
      <img v-else :src="owners[0]" alt="owner" class="img__shape" />
    </div>
    <div
      class="absolute left-9 top-1 parula__text-gray text-sm md:text-base owner-name-1"
    >
      {{ lastOwnerName }}
    </div>
  </div>

  <div v-else-if="owners.length === 2" class="relative">
    <div class=" w-8 h-8 rounded-full parula__bg-white">
      <img v-if="!owners[0]" :src="pic" alt="owner" class="img__shape" />
      <img v-else :src="owners[0]" alt="owner" class="img__shape" />
    </div>
    <div class=" absolute w-8 h-8 top-0 left-6 rounded-full parula__bg-white">
      <img v-if="!owners[1]" :src="pic" alt="owner" class="img__shape" />
      <img v-else :src="owners[1]" alt="owner" class="img__shape" />
    </div>
    <div
      class="absolute left-16 top-1 parula__text-gray text-sm md:text-base owner-name-2"
    >
      {{ lastOwnerName }}
    </div>
  </div>

  <div v-else-if="owners.length === 3" class="relative">
    <div class=" w-8 h-8 rounded-full parula__bg-white">
      <img v-if="!owners[0]" :src="pic" alt="owner" class="img__shape" />
      <img v-else :src="owners[0]" alt="owner" class="img__shape" />
    </div>
    <div class=" absolute w-8 h-8 top-0 left-6 rounded-full parula__bg-white">
      <img v-if="!owners[1]" :src="pic" alt="owner" class="img__shape" />
      <img v-else :src="owners[1]" alt="owner" class="img__shape" />
    </div>
    <div class=" absolute w-8 h-8 top-0 left-12 rounded-full parula__bg-white">
      <img v-if="!owners[2]" :src="pic" alt="owner" class="img__shape" />
      <img v-else :src="owners[2]" alt="owner" class="img__shape" />
    </div>
    <div
      class="absolute left-22 top-1 text-sm parula__text-gray text-sm md:text-base owner-name-3"
    >
      {{ lastOwnerName }}
    </div>
  </div>

  <div v-else-if="owners.length > 3" class="relative flex">
    <div class=" w-8 h-8 rounded-full parula__bg-white">
      <img v-if="!owners[0]" :src="pic" alt="owner" class="img__shape" />
      <img v-else :src="owners[0]" alt="owner" class="img__shape" />
    </div>
    <div class=" absolute w-8 h-8 top-0 left-6 rounded-full parula__bg-white">
      <img v-if="!owners[1]" :src="pic" alt="owner" class="img__shape" />
      <img v-else :src="owners[1]" alt="owner" class="img__shape" />
    </div>
    <div class=" absolute w-8 h-8 top-0 left-12 rounded-full parula__bg-white">
      <img v-if="!owners[2]" :src="pic" alt="owner" class="img__shape" />
      <img v-else :src="owners[2]" alt="owner" class="img__shape" />
    </div>
    <div class="absolute left-22 top-1 text-sm md:text-base">・・・</div>
  </div>
</template>

<script>
import pic from "@/assets/img/basic_image.png";

export default {
  props: {
    owners: {
      type: Array,
      required: true,
    },
    lastOwnerName: {
      type: String,
      required: true,
    },
  },

  data: function() {
    return {
      pic,
    };
  },
};
</script>

<style lang="scss" scoped>
// @import "@/scss/_layout.scss";
@import "@/scss/_variables.scss";
@import "@/scss/_text.scss";

.left-22 {
  left: 5.5rem;
}

.img__shape {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  background-color: #fff;
  border: 1px solid $gray-soft;
}

.owner-name-1 {
  width: calc(100% - 2.25rem);
  @extend %text__ellipsis;
}

.owner-name-2 {
  width: calc(100% - 4rem);
  @extend %text__ellipsis;
}

.owner-name-3 {
  width: calc(100% - 5.5rem);
  @extend %text__ellipsis;
}
</style>
