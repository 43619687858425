<template>
  <article class="card flex">
    <section class="card__image">
      <!-- <img
        :src="img"
        alt="img"
        class="w-full h-full object-cover radius__left"
      /> -->
      <content-viewer :filePath="img" :radius-left="true" />
    </section>
    <section class="card__description">
      <div
        class="card__description__title font-bold text-lg md:text-xl xl:text-2xl"
      >
        {{ title }}
      </div>
      <div>
        <OwnerIcon :owners="owners" :last-owner-name="lastOwnerName" />
      </div>
      <div class="flex items-center">
        <span class="parula__text-gray font-semibold text-sm md:text-base">{{
          price
        }}</span>
        <img :src="symbol" alt="symbol" class="ml-1 w-5 h-5" />
      </div>
    </section>
  </article>
</template>

<script>
import OwnerIcon from "@/components/card/OwnerIcon.vue";
import OpenapiService from "@/services/openapi.service.js";
import ContentViewer from "@/components/ContentViewer.vue";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    symbol: {
      type: String,
      required: true,
    },
  },

  components: {
    OwnerIcon,
    ContentViewer,
  },

  data() {
    return {
      owners: [],
      lastOwnerName: "",
    };
  },

  async created() {
    await OpenapiService.getOwners(this.id).then((res) => {
      res.data.forEach((element) => {
        this.owners.push(element.avatar_min);
        this.lastOwnerName = element.nickname;
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_text.scss";
@import "@/scss/_variables.scss";

.card {
  width: 100%;
  height: 100%;
  border: 1px solid $gray-soft;
  border-radius: $radius $radius $radius $radius;

  .card__image {
    width: 55%;

    .radius__left {
      border-radius: $radius 0 0 $radius;
    }
  }
  .card__description {
    align-self: end;
    width: 45%;
    height: 60%;
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    .card__description__title {
      @extend %text__ellipsis;
    }
  }
}
</style>
