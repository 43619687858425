<template>
  <div class="flex flex-col gap-y-24 relative">
    <!-- notification 위치 -->
    <notifications position="bottom right" />

    <!-- Main Video Section -->
    <section>
      <!-- 비디오 -->
      <div class="relative videoForm">
        <!-- <video
          src="@/assets/video/mainVideo.mp4"
          autoplay
          muted
          loop
          playsinline
          class="w-full h-full object-cover"
        /> -->

        <img src="@/assets/img/cilo_main.png" class="w-full h-full object-cover"/>

        <!-- 비디오 가림막 -->
        <!-- <div
          class="
            VideoBlind
            absolute
            bg__black
            flex
            items-center
            justify-center
            w-full
            h-full
            left-0
            top-0
            opacity-5
            z-10
          "
        ></div> -->
      </div>
    </section>

    <!-- Spotlight Section -->
    <section>
      <div class="adjustment">
        <index-spotlight />
      </div>
    </section>

    <!-- main carousel -->
    <section class="layout__carousel__main flex items-center">
      <div class="adjustment">
        <h1
          class="
            text-lg
            sm:text-xl
            md:text-2xl
            text-center
            sm:text-left
            font-semibold
            mb-10
          "
        >
          Recent Activity
        </h1>

        <div v-if="recentlyActivityList.length === 0">
          No items
        </div>
        <div v-else>
          <main-carousel :items="recentlyActivityList" />
        </div>
      </div>
    </section>

    <!-- normal carousel -->
    <section>
      <div class="adjustment">
        <h1
          class="
            text-lg
            sm:text-xl
            md:text-2xl
            text-center
            sm:text-left
            font-semibold
            mb-10
          "
        >
          Most Popular
        </h1>

        <div v-if="recentlyActivityList.length === 0">
          No items
        </div>
        <div v-else>
          <normal-carousel :items="popularList" />
        </div>
      </div>
    </section>

    <!-- auction carousel -->
    <section>
      <div class="adjustment">
        <h1
          class="
            text-lg
            sm:text-xl
            md:text-2xl
            text-center
            sm:text-left
            font-semibold
            mb-10
          "
        >
          Recent Auctions
        </h1>

        <div v-if="auctionList.length === 0">
          No items
        </div>
        <div v-else>
          <normal-carousel :items="auctionList" :card-type="'auction'" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import IndexSpotlight from "./sub/IndexSpotlight.vue";
import MainCarousel from "@/components/slide-list/MainCarousel.vue";
import NormalCarousel from "@/components/slide-list/NormalCarousel.vue";
// import SlideList from "@/components/slide-list/SlideList.vue";
// import SlideItemNone from "@/components/slide-list/SlideItemNone.vue";
import OpenapiService from "@/services/openapi.service";
import { removeUnusedZero, setVisualPrice } from "@/util/price";

export default {
  data() {
    return {
      recentlyActivityList: [],
      popularList: [],
      auctionList: [],
      DISPLAYING_DIGITS: 3,
    };
  },

  components: {
    IndexSpotlight,
    // SlideList,
    // SlideItemNone,
    MainCarousel,
    NormalCarousel,
  },

  created() {
    OpenapiService.orderDisplayListStateBy("").then((res) => {
      // TODO: popular 기준으로 가져오기
      this.popularList = res.data.items;

      for (var i = 0; i < this.popularList.length; i++) {
        if (this.popularList[i].order_type === 1) {
          //일반판매일경우
          this.popularList[i].num_price = removeUnusedZero(
            this.popularList[i].price_fixed,
            this.popularList[i].assetDecimals
          );
          this.popularList[i].visual_price = setVisualPrice(
            this.popularList[i].num_price,
            this.DISPLAYING_DIGITS
          );
        } else {
          //경매일경우
          this.popularList[i].num_price = removeUnusedZero(
            this.popularList[i].price_begin,
            this.popularList[i].assetDecimals
          );
          this.popularList[i].visual_price = setVisualPrice(
            this.popularList[i].num_price,
            this.DISPLAYING_DIGITS
          );
        }
      }
    });

    OpenapiService.orderDisplayList().then((res) => {
      this.recentlyActivityList = res.data.items;

      for (var i = 0; i < this.recentlyActivityList.length; i++) {
        if (this.recentlyActivityList[i].order_type === 1) {
          //일반판매일경우
          this.recentlyActivityList[i].num_price = removeUnusedZero(
            this.recentlyActivityList[i].price_fixed,
            this.recentlyActivityList[i].assetDecimals
          );
          this.recentlyActivityList[i].visual_price = setVisualPrice(
            this.recentlyActivityList[i].num_price,
            this.DISPLAYING_DIGITS
          );
        } else {
          //경매일경우
          this.recentlyActivityList[i].num_price = removeUnusedZero(
            this.recentlyActivityList[i].price_begin,
            this.recentlyActivityList[i].assetDecimals
          );
          this.recentlyActivityList[i].visual_price = setVisualPrice(
            this.recentlyActivityList[i].num_price,
            this.DISPLAYING_DIGITS
          );
        }
      }
    });

    const param = {
      type: "auctionOnly",
    };

    OpenapiService.orderDisplayList(1, 20, param).then((res) => {
      this.auctionList = res.data.items;

      for (var i = 0; i < this.auctionList.length; i++) {
        if (this.auctionList[i].order_type === 1) {
          //일반판매일경우
          this.auctionList[i].num_price = removeUnusedZero(
            this.auctionList[i].price_fixed,
            this.auctionList[i].assetDecimals
          );
          this.auctionList[i].visual_price = setVisualPrice(
            this.auctionList[i].num_price,
            this.DISPLAYING_DIGITS
          );
        } else {
          //경매일경우
          this.auctionList[i].num_price = removeUnusedZero(
            this.auctionList[i].price_begin,
            this.auctionList[i].assetDecimals
          );
          this.auctionList[i].visual_price = setVisualPrice(
            this.auctionList[i].num_price,
            this.DISPLAYING_DIGITS
          );
        }
      }
    });
  },
};
</script>

<style lang="scss">
@import "@/scss/_layout.scss";
@import "@/scss/_text.scss";
@import "@/scss/_variables.scss";

.layout__spotlight {
  min-height: 25rem;
  height: calc(100vh - 70px);
}

.layout__carousel__main {
  min-height: 26rem;
  height: 70vh;
  padding: 2.5rem 0px;
  background-color: #f8f8f8;
}

.carousel__viewport {
  padding: 0 0 1.5rem 0;
}

.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  background-color: transparent;
  border: 1px solid $black;
  border-radius: 50%;
}

.carousel__icon {
  fill: #262626;
}

// .carousel__item {
//   min-height: 16rem;
//   height: 42vh;
//   width: 90%;
//   // background-color: var(#fff);
//   // background-color: none;
//   background-color: #ffffff;
//   filter: opacity(100%);
//   color: var(--vc-clr-white);
//   // font-size: 20px;
//   // border-radius: 8px;
//   // display: flex;
//   // justify-content: start;
//   // align-items: center;
//   text-align: start;
//   border-radius: $radius $radius $radius $radius;
// }

// .carousel__prev--in-active,
// .carousel__next--in-active {
//   display: none;
// }

// .carousel__prev,
// .carousel__next {
//   box-sizing: content-box;
//   background-color: transparent;
//   border: 1px solid $black;
//   border-radius: 50%;
// }
// .carousel__icon {
//   fill: #262626;
// }

.adjustment {
  margin: auto;
  @include sm {
    width: 90%;
  }

  @include md {
    width: 80%;
  }

  @include lg {
    width: 80%;
  }

  @include xl {
    width: calc(100% - 288px);
  }

  @include xxl {
    width: 1440px;
  }
}

.videoForm {
  height: 92vh;
}
</style>
