<template>
  <article class="card card-shadow">
    <div class="flex justify-center items-center card__image relative">
      <div ref="Background" class="card__background">
        <div ref="Blur" class="blur"></div>
      </div>
      <!-- <img
        ref="Image"
        :src="img"
        alt="img"
        class="object-contain w-full h-full card__img__radius"
      /> -->
      <!-- <img
        ref="Image"
        :src="img"
        alt="img"
        class="object-contain w-full h-full"
        :class="{ card__col__img: cardCol, card__row__img: cardRow }"
      /> -->
      <content-viewer
        :filePath="img"
        :radius-top="true"
        object-fit="object-contain"
      />
      <div class="blur-cover"></div>
    </div>
    <div class="card__description">
      <div
        class="card__description__title font-bold text-base md:text-lg xl:text-xl"
      >
        {{ title }}
      </div>
      <div>
        <span class="parula__text-gray font-semibold text-sm md:text-base"
          >@{{ creator_pen_name }}</span
        >
        <!-- <owner-icon :owners="owners" :last-owner-name="lastOwnerName" /> -->
      </div>
      <div class="flex items-center justify-self-end">
        <!-- <span class="parula__text-gray font-semibold text-sm md:text-base"
          >Asset</span
        > -->
        <img :src="symbol" alt="symbol" class="w-8 h-8" />
      </div>
    </div>
  </article>
</template>

<script>
import OpenapiService from "@/services/openapi.service.js";
import ContentViewer from "@/components/ContentViewer.vue";

// :img="item.thumbnail"
// :title="item.title"
// :creator_pen_name="item.creator_pen_name"
// :symbol="item.symbol"

export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    creator_pen_name: {
      type: String,
      required: true,
    },
    symbol: {
      type: String,
      required: true,
    },
  },

  components: {
    ContentViewer,
  },

  data() {
    return {
      cardCol: false,
      cardRow: false,

      owners: [],
      lastOwnerName: "",
    };
  },

  async created() {
    await OpenapiService.getOwners(this.id).then((res) => {
      res.data.forEach((element) => {
        this.owners.push(element.avatar_min);
        this.lastOwnerName = element.nickname;
      });
    });
  },

  mounted() {
    let background = `url(${this.img})`;

    if (this.img) {
      const filePath = this.img;
      const lastDot = filePath.lastIndexOf(".");
      const fileExt = filePath.substring(lastDot + 1).toUpperCase();

      if (fileExt === "MP4") {
        background = "#262626";
      } else {
        this.$refs.Background.style.filter = "opacity(75%)";
        this.$refs.Blur.style.backdropFilter = "blur(0.5rem)";
      }
    }

    this.$refs.Background.style.background = background;
    this.$refs.Background.style.backgroundPosition = "center";
    this.$refs.Background.style.backgroundSize = "cover";
  },

  methods: {
    // decideImgShape() {
    //   const img = new Image();
    //   img.src = this.img;
    //   const { width, height } = img;
    //   width > height ? (this.cardRow = true) : (this.cardCol = true);
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_text.scss";
@import "@/scss/_variables.scss";

$description__height: 8rem;

.card {
  width: 100%;
  height: 100%;
  border: 2px solid $gray-soft;
  border-radius: $radius $radius $radius $radius;

  .card__image {
    position: relative;
    background-image: none;
    z-index: -2;
    width: 100%;
    height: calc(100% - $description__height);

    .card__img__radius {
      border-radius: $radius $radius 0 0;
    }

    // .card__col__img {
    //   width: 100%;
    //   height: 100%;
    //   border-radius: $radius $radius 0 0;
    //   // max-height: calc(100% - ($radius * 2));
    // }

    // .card__row__img {
    //   width: 100%;
    //   height: 100%;
    //   border-radius: $radius $radius 0 0;
    // }

    .blur-cover {
      position: absolute;
      bottom: -0.05rem;
      width: 100%;
      height: 0.1rem;
      background-color: #fff;
    }

    .card__background {
      position: absolute;
      z-index: -1;
      top: 0;
      width: 100%;
      height: 100%;
      // background: url("https://source.unsplash.com/random/1");
      // background-position: center;
      // background-size: cover;
      // filter: opacity(75%);
      // filter: blur(10px);
      // backdrop-filter: blur(5px);
      border-radius: $radius $radius 0px 0px;
      .blur {
        // background: rgba(
        //   255,
        //   255,
        //   255,
        //   0.2
        // ); // Make sure this color has an opacity of less than 1
        // backdrop-filter: blur(0.5rem); // This be the blur

        height: 100%;
        width: 100%;
        // border: 1px solid $gray-soft;
        // border-bottom: none;
        border-radius: $radius $radius 0px 0px;
      }
    }
  }
  .card__description {
    height: $description__height;
    padding: 0.5rem 1rem;
    display: grid;
    align-items: center;
    grid-template-rows: repeat(3, 1fr);
    .card__description__title {
      @extend %text__ellipsis;
    }
  }
}

.card-shadow {
  box-shadow: 0.5rem 0.5rem 1rem 0px rgb(38, 38, 38, 0.05);
  &:hover {
    box-shadow: 0.5rem 0.5rem 1rem 0px rgb(38, 38, 38, 0.2);
  }
}
</style>
