<template>
  <Carousel
    :settings="settings"
    :breakpoints="breakpoints"
    :items-to-show="itemToShow"
    :wrap-around="true"
    :pauseAutoplayOnHover="true"
    :autoplay="5000"
  >
    <Slide v-for="(item, index) in items" :key="index">
      <div
        class="carousel__item cursor-pointer"
        @mousedown="mouseDown($event)"
        @mouseup="onDetailPage(item.id, item.order_id, item.product_id, $event)"
      >
        <normal-card
          v-if="cardType === 'normal'"
          :id="item.content_id"
          :img="item.thumbnail"
          :title="item.title"
          :price="item.visual_price"
          :symbol="item.assetSymbolImg"
        />

        <auction-card
          v-else-if="cardType === 'auction'"
          :order-state="item.order_state"
          :id="item.content_id"
          :img="item.thumbnail"
          :title="item.title"
          :price="item.visual_price"
          :symbol="item.assetSymbolImg"
          :end_at="item.end_at"
        />

        <product-card
          v-else-if="cardType === 'product'"
          :img="item.thumbnail"
          :title="item.title"
          :creator_pen_name="item.creator_pen_name"
          :symbol="item.asset_symbol_img"
        />

        <auction-status-card
          v-else-if="cardType === 'auction-status'"
          :order-state="item.order_state"
          :img="item.thumbnail"
          :order_state="item.order_state"
          :bid_price="item.visual_bid_price"
          :highest_bid_price="item.visual_highest_bid_price"
          :end_at="item.end_at"
          :symbol="item.asset_symbol_img"
          :buyer_id="item.buyer_id"
          :bidder_id="item.bidder_id"
        />
      </div>
    </Slide>

    <template #addons>
      <Navigation />
      <!-- <Pagination /> -->
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

import NormalCard from "@/components/card/NormalCard.vue";
import AuctionCard from "@/components/card/AuctionCard.vue";
import ProductCard from "@/components/card/ProductCard.vue";
import AuctionStatusCard from "@/components/card/AuctionStatusCard.vue";

import "vue3-carousel/dist/carousel.css";
import { removeUnusedZero } from "@/util/price.js";

export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true,
    },
    linkTo: {
      type: String,
      default: "order",
    },
    cardType: {
      type: String,
      default: "normal",
    },
  },

  data() {
    return {
      settings: {
        itemsToShow: 1.2,
        snapAlign: "center",
      },
      breakpoints: {
        // 700px and up
        768: {
          itemsToShow: 2,
          snapAlign: "start",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        1440: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
      itemToShow: 1,
      correction: 50,
      clientX: 0,
    };
  },

  components: {
    Carousel,
    Slide,
    Navigation,
    NormalCard,
    AuctionCard,
    ProductCard,
    AuctionStatusCard,
  },

  // created() {
  //   console.log("normal this.items: ", this.items);
  //   // TODO 마우스를 땔 때만 실행되도록 수정
  //   this.handleResizeHandler();
  //   window.addEventListener("resize", this.handleResizeHandler);
  // },

  // beforeUnmount() {
  //   window.removeEventListener("resize", this.handleResizeHandler);
  // },

  methods: {
    // handleResizeHandler() {
    //   const { innerWidth } = window;

    //   if (innerWidth < 768) {
    //     this.itemToShow = 1;
    //   } else this.itemToShow = 2;
    // },
    // },

    parsedPrice(price, decimal) {
      return removeUnusedZero(price, decimal);
    },

    mouseDown(event) {
      this.clientX = event.clientX;
    },

    onDetailPage(temp_id, order_id, product_id, event) {
      let id;
      id = this.linkTo === "order" ? order_id : product_id;

      if (!id) id = temp_id;

      if (
        this.linkTo === "order" &&
        Math.abs(this.clientX - event.clientX) < this.correction
      ) {
        this.$router.push(`/market/order/${id}`);
      } else if (
        this.linkTo === "product" &&
        Math.abs(this.clientX - event.clientX) < this.correction
      ) {
        this.$router.push(`/product/${id}`);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
// .carousel__viewport {
//   padding: 0 0 1.5rem 0;
// }

.carousel__item {
  min-height: 20rem;
  height: 55vh;
  max-height: 28rem;
  width: 90%;
  // background-color: var(#fff);
  // background-color: none;
  background-color: #ffffff;
  filter: opacity(100%);
  color: var(--vc-clr-white);
  // font-size: 20px;
  // border-radius: 8px;
  // display: flex;
  // justify-content: start;
  // align-items: center;
  text-align: start;
  border-radius: $radius $radius $radius $radius;
}

// .carousel__prev--in-active,
// .carousel__next--in-active {
//   display: none;
// }

// .carousel__prev,
// .carousel__next {
//   box-sizing: content-box;
//   background-color: transparent;
//   border: 1px solid $black;
//   border-radius: 50%;
// }
// .carousel__icon {
//   fill: #262626;
// }
</style>
