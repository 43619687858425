<template>
  <Carousel
    :settings="settings"
    :breakpoints="breakpoints"
    :items-to-show="itemToShow"
    :wrap-around="true"
    :pauseAutoplayOnHover="true"
    :autoplay="5000"
  >
    <Slide v-for="(item, index) in items" :key="index">
      <div
        class="carousel__item cursor-pointer"
        @mousedown="mouseDown($event)"
        @mouseup="onDetailPage(item.order_id, $event, item.id)"
      >
        <main-card
          :id="item.content_id"
          :img="item.thumbnail"
          :title="item.title"
          :price="item.visual_price"
          :symbol="item.assetSymbolImg"
        />
      </div>
    </Slide>

    <template #addons>
      <Navigation />
      <!-- <Pagination /> -->
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

import MainCard from "@/components/card/MainCard.vue";
import "vue3-carousel/dist/carousel.css";
import ContentViewer from "@/components/ContentViewer";
import { removeUnusedZero } from "@/util/price.js";

export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true,
    },
    linkTo: {
      type: String,
      default: "order",
    },
  },

  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        // 700px and up
        1440: {
          itemsToShow: 2,
          snapAlign: "start",
        },
      },
      itemToShow: 1,
      correction: 50,
      clientX: 0,
    };
  },

  components: {
    ContentViewer,
    Carousel,
    Slide,
    Navigation,
    Pagination,
    MainCard,
  },

  // created() {
  //   console.log("this.items: ", this.items);
  //   // TODO 마우스를 땔 때만 실행되도록 수정
  //   this.handleResizeHandler();
  //   window.addEventListener("resize", this.handleResizeHandler);
  // },

  // beforeUnmount() {
  //   window.removeEventListener("resize", this.handleResizeHandler);
  // },

  methods: {
    // handleResizeHandler() {
    //   const { innerWidth } = window;

    //   if (innerWidth < 768) {
    //     this.itemToShow = 1;
    //   } else this.itemToShow = 2;
    // },

    parsedPrice(price, decimal) {
      return removeUnusedZero(price, decimal);
    },

    mouseDown(event) {
      this.clientX = event.clientX;
    },

    onDetailPage(id, event, sub_id) {
      let param_id;
      id ? (param_id = id) : (param_id = sub_id);

      if (
        this.linkTo === "order" &&
        Math.abs(this.clientX - event.clientX) < this.correction
      ) {
        this.$router.push(`/market/order/${param_id}`);
      } else if (
        this.linkTo === "product" &&
        Math.abs(this.clientX - event.clientX) < this.correction
      ) {
        this.$router.push(`/product/${param_id}`);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.carousel__item {
  min-height: 16rem;
  height: 42vh;
  max-height: 28rem;
  width: 90%;

  // background-color: var(#fff);
  // background-color: none;
  background-color: #ffffff;
  filter: opacity(100%);
  color: var(--vc-clr-white);
  // font-size: 20px;
  // border-radius: 8px;
  // display: flex;
  // justify-content: start;
  // align-items: center;
  text-align: start;
  border-radius: $radius $radius $radius $radius;
}

// .carousel__prev--in-active,
// .carousel__next--in-active {
//   display: none;
// }

// .carousel__prev,
// .carousel__next {
//   box-sizing: content-box;
//   background-color: transparent;
//   border: 1px solid $black;
//   border-radius: 50%;
// }
// .carousel__icon {
//   fill: #262626;
// }
</style>
