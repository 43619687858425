<template>
  <section class="layout__spotlight">
    <article class="flex flex-col md:flex-row">
      <section class="relative w-full md:order-1">
        <div class="layout__image__main">
          <img
            v-if="!image"
            src="@/assets/img/CILO_logo_symbol.png"
            alt="img"
            class="w-full h-full object-cover"
          />
          <img
            v-else
            :src="image"
            alt="img"
            class="w-full h-full object-cover"
          />
        </div>
      </section>
      <section
        class="layout__description flex flex-col md:justify-between items-center md:items-start p-2 mt-14 md:mt-0"
      >
        <div class="w-full">
          <h1
            class="
              text-lg
              sm:text-xl
              md:text-2xl
              lg:text-3xl
              text-center
              md:text-left
              font-semibold
              mb-10
              md:mb-20
            "
          >
            Artist Spotlight
          </h1>
          <div
            class="font-bold text-lg md:text-xl lg:text-2xl mb-5 lg:mb-20 text__ellipsis text-center md:text-left"
          >
            {{ title }}
          </div>

          <div
            class="parula__text-gray lg:text-lg description-height overflow-y-auto hide-scrollbar text-center md:text-left"
            v-html="description"
          ></div>
        </div>

        <button @click="onGoLink" class="button-black mt-5">
          <span class="text-xs md:text-sm xl:text-base parula__text-white"
            >See More</span
          >
        </button>
      </section>
    </article>
  </section>
</template>

<script>
import OpenapiService from "@/services/openapi.service";

export default {
  data() {
    return {
      title: "CILO",
      description: "CILO & NFT",
      external_link: "",
      image: "",
    };
  },

  created() {
    this.getSpotlightInfo();
  },

  methods: {
    getSpotlightInfo() {
      OpenapiService.getLatestSpotlight()
        .then((result) => {
          const { title, description, external_link, image } = result.data;
          console.log(result.data);

          this.title = title;
          this.description = description;
          this.external_link = external_link;
          this.image = image;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onGoLink() {
      if (!this.external_link) this.$router.push("/market");
      else location.href = this.external_link;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_layout.scss";
@import "@/scss/_text.scss";
@import "@/scss/_button.scss";
@import "@/scss/_scrollbar.scss";

.layout__spotlight {
  width: 100%;
  min-height: 25rem;
  height: 100%;
}

.layout__image__main {
  width: 100%;
  height: calc((100vh - 70px) * 2 / 5);
  border: 1px solid $gray-soft;
  border-radius: $radius $radius $radius $radius;
  @include md {
    height: calc((100vh - 70px) * 3 / 5);
  }
  @include min-lg {
    height: calc((100vh - 70px) * 4 / 5);
  }
}

// .layout__image__sub {
//   position: absolute;
//   left: 50%;
//   transform: translate(-50%, -70%);

//   width: 40%;
//   height: calc((100vh - 70px) * 1 / 5);
//   border: 1px solid $gray-soft;
//   border-radius: $radius $radius $radius $radius;

//   @include min-md {
//     left: 95%;
//     width: 10rem;
//     height: 10rem;
//   }

//   @include min-lg {
//     left: 95%;
//     width: 15rem;
//     height: 15rem;
//   }
// }

img {
  border-radius: $radius $radius $radius $radius;
  @include min-md {
    min-height: 20rem;
  }
}

.layout__description {
  width: 100%;
  height: calc((100vh - 70px) * 2 / 5);

  .description-height {
    max-height: calc((100vh - 70px) * 1 / 6);
  }

  @include md {
    width: 60%;
    height: calc((100vh - 70px) * 3 / 5);
    padding: 0 5rem 6rem 0;
    .description-height {
      max-height: calc((100vh - 70px) * 1 / 4);
    }
  }

  @include min-lg {
    width: 50%;
    height: calc((100vh - 70px) * 4 / 5);
    padding: 0 7rem 6rem 0;
    .description-height {
      max-height: calc((100vh - 70px) * 1 / 3);
    }
  }
}

.button-black {
  @include button-radius(7.5rem, 2.5rem, $black);
}

.text__ellipsis {
  @extend %text__ellipsis;
}

// .hide-scrollbar {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }

// .hide-scrollbar::-webkit-scrollbar {
//   display: none; /* Chrome, Safari, Opera*/
// }
</style>
