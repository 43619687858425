<template>
  <article class="relative card card-shadow">
    <div class="flex justify-center items-center card__image relative">
      <div ref="Background" class="card__background">
        <div ref="Blur" class="blur"></div>
      </div>
      <!-- <img
        ref="Image"
        :src="img"
        alt="img"
        class="object-contain w-full h-full card__img__radius"
      /> -->
      <content-viewer
        :filePath="img"
        :radius-top="true"
        object-fit="object-contain"
      />
      <div class="blur-cover"></div>
    </div>
    <div class="card__description">
      <div class="flex items-end justify-between h-full">
        <span class="parula__text-gray font-semibold text-sm md:text-base">
          My Bid
        </span>
        <!-- <span class="parula__text-black font-semibold text-sm md:text-base">
          {{ bid_price }} {{ asset_symbol }}
        </span> -->
        <div class="flex items-center">
          <span class="parula__text-gray font-semibold text-sm md:text-base">{{
            highest_bid_price
          }}</span>
          <img :src="symbol" alt="symbol" class="ml-1 w-5 h-5" />
        </div>
      </div>

      <div class="flex items-center justify-between">
        <span class="parula__text-gray font-semibold text-sm md:text-base">
          {{ wholeBidTitle }}
        </span>
        <!-- <span class="parula__text-black font-semibold text-sm md:text-base">
           {{ highest_bid_price }} {{ asset_symbol }}
        </span> -->
        <div class="flex items-center">
          <span class="parula__text-gray font-semibold text-sm md:text-base">{{
            highest_bid_price
          }}</span>
          <img :src="symbol" alt="symbol" class="ml-1 w-5 h-5" />
        </div>
      </div>

      <div
        class="auction-info__time absolute z-10 w-40 lg:w-44 h-8 bottom-36 right-0 card-radius"
      >
        <span v-html="htmlCounter"></span>
      </div>
    </div>

    <div
      class="auction-info__status bottom-0 font-semibold text-xs md:text-sm"
      ref="BottomTag"
    >
      {{ bottomTag }}
    </div>
  </article>
</template>

<script>
import OpenapiService from "@/services/openapi.service.js";
import ContentViewer from "@/components/ContentViewer.vue";

const ORDER_STATE = {
  onSales: 1,
  hold: 2,
  sold: 3,
  closed: 4,
};

const AUCTION_RESULT = {
  onGoingAndHighestBidder: 1, //   Highest: 0, 가장 높은 가격이 나야
  onGoingAndFallingBehindInBidding: 2, //   OutTheBid: 1, 가장 높은 가격이 내가 아니야
  closedAndWonTheAuction: 3, //   WinTheAuction: 3, 오너가 있어 근데 그게 나야
  closedAndLostTheAuction: 4, //   LoseTheAuction: 2, 오너가 있어 근데 내가 아니야
  closedAndNoWinner: 5, //   CancelTheAuction: 4, 오너가 없어, 유찰
  closedAndHostIsCheckingIt: 6, //   경매 끝났지만, host가 확인 중
};

export default {
  props: {
    orderState: {
      type: Number,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    order_state: {
      type: Number,
      required: true,
    },
    bid_price: {
      type: String,
      required: true,
    },
    highest_bid_price: {
      type: String,
      required: true,
    },
    end_at: {
      type: String,
      required: true,
    },
    symbol: {
      type: String,
      required: true,
    },
    buyer_id: {
      type: Number,
    },
    bidder_id: {
      type: Number,
    },
  },

  data() {
    return {
      htmlCounter: "",
      state: "",

      owners: [],
      lastOwnerName: "",

      // myBidTitle: "",
      wholeBidTitle: "",
      bottomTag: "",
    };
  },

  components: {
    ContentViewer,
  },

  async created() {
    await OpenapiService.getOwners(this.id).then((res) => {
      res.data.forEach((element) => {
        this.owners.push(element.avatar_min);
        this.lastOwnerName = element.nickname;
      });
    });
  },

  mounted() {
    let background = `url(${this.img})`;

    if (this.img) {
      const filePath = this.img;
      const lastDot = filePath.lastIndexOf(".");
      const fileExt = filePath.substring(lastDot + 1).toUpperCase();

      if (fileExt === "MP4") {
        background = "#262626";
      } else {
        this.$refs.Background.style.filter = "opacity(75%)";
        this.$refs.Blur.style.backdropFilter = "blur(0.5rem)";
      }
    }

    this.$refs.Background.style.background = background;
    this.$refs.Background.style.backgroundPosition = "center";
    this.$refs.Background.style.backgroundSize = "cover";
    // this.$refs.background.style.background = `url(${this.img})`;
    // this.$refs.background.style.backgroundPosition = "center";
    // this.$refs.background.style.backgroundSize = "cover";

    this.setState();
    this.setupInterval();
  },

  // updated() {
  //   this.setState();
  // },

  methods: {
    setState: function() {
      const now = new Date(); // local time
      const end = new Date(this.end_at); // local time

      if (now < end) {
        // now < end; // 진행중
        this.wholeBidTitle = "Highest Bid";

        if (this.bid_price === this.highest_bid_price) {
          this.state = AUCTION_RESULT.onGoingAndHighestBidder;
          this.bottomTag = "I'm the highest bidder";
          this.$refs.BottomTag.style.backgroundColor = "#4984EE";
          this.$refs.BottomTag.style.color = "#fff";
        } else {
          this.state = AUCTION_RESULT.onGoingAndFallingBehindInBidding;
          this.bottomTag = "Out of bid";
          this.$refs.BottomTag.style.backgroundColor = "#4D525C";
          this.$refs.BottomTag.style.color = "#fff";
        }
      } else {
        // now >= end; // 종료
        this.wholeBidTitle = "Final Bid";

        if (this.order_state === ORDER_STATE.sold) {
          if (this.buyer_id === this.bidder_id) {
            // 내 bid만 가져오는 거라 이거 가능할 듯
            this.state = AUCTION_RESULT.closedAndWonTheAuction;
            this.bottomTag = "Won the auction !";
            this.$refs.BottomTag.style.backgroundColor = "#F3BF4B";
            this.$refs.BottomTag.style.color = "#262626";
          } else {
            this.state = AUCTION_RESULT.closedAndLostTheAuction;
            this.bottomTag = "Lost the auction";
            this.$refs.BottomTag.style.backgroundColor = "#2F3747";
            this.$refs.BottomTag.style.color = "#fff";
          }
        } else if (this.order_state === ORDER_STATE.closed) {
          this.state = AUCTION_RESULT.closedAndNoWinner;
          this.bottomTag = "The Auction is canceled";
          this.$refs.BottomTag.style.backgroundColor = "#262626";
          this.$refs.BottomTag.style.color = "#fff";
        } else {
          this.state = AUCTION_RESULT.closedAndHostIsCheckingIt;
          this.bottomTag = "The host is checking";
          this.$refs.BottomTag.style.backgroundColor = "#CDD5DF";
          this.$refs.BottomTag.style.color = "#262626";
        }
      }
    },

    setupInterval() {
      const t = this.remainTime(this.end_at);

      if (t.total < 0 || this.orderState === 3) {
        this.htmlCounter =
          "<span class='text-xs lg:text-sm parula__text-gray'>Auction Ended</span>";
        return;
      }
      this.interval = setInterval(() => {
        if (this.end_at) {
          const t = this.remainTime(this.end_at);
          //
          const html = `
            <span class="-mr-1 text-xs lg:text-sm parula__text-gray">${
              t.days
            }</span>
            <span class="text-xs lg:text-sm parula__text-gray">D</span>
            <span class="-mr-1 text-xs lg:text-sm parula__text-gray">${(
              "00" + t.hours
            ).slice(-2)}</span>
            <span class="text-xs lg:text-sm parula__text-gray">H</span>
            <span class="-mr-1 text-xs lg:text-sm parula__text-gray">${(
              "00" + t.minutes
            ).slice(-2)}</span>
            <span class="text-xs lg:text-sm parula__text-gray">M</span>
            <span class="-mr-1 text-xs lg:text-sm parula__text-gray">${(
              "00" + t.seconds
            ).slice(-2)}</span>
            <span class="text-xs lg:text-sm parula__text-gray">S</span>
          `;
          this.htmlCounter = html;
        } else {
          this.htmlCounter = "";
        }
      }, 1000);
    },

    remainTime(endTime) {
      const total = Date.parse(endTime) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));

      return {
        total,
        days,
        hours,
        minutes,
        seconds,
      };
    },

    unmounted() {
      console.log("unmounted !");
      clearInterval(this.interval);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_text.scss";
@import "@/scss/_variables.scss";

$description__height: 10rem;

.card {
  width: 100%;
  height: 100%;
  border: 2px solid $gray-soft;
  border-radius: $radius $radius $radius $radius;

  .card__image {
    position: relative;
    background-image: none;
    z-index: -2;
    width: 100%;
    height: calc(100% - $description__height);

    .card__img__radius {
      border-radius: $radius $radius 0 0;
    }

    // .card__col__img {
    //   width: 100%;
    //   height: 100%;
    //   border-radius: $radius $radius 0 0;
    //   // max-height: calc(100% - ($radius * 2));
    // }

    // .card__row__img {
    //   width: 100%;
    //   height: 100%;
    //   border-radius: $radius $radius 0 0;
    // }

    .blur-cover {
      position: absolute;
      bottom: -0.05rem;
      width: 100%;
      height: 0.1rem;
      background-color: #fff;
    }

    .card__background {
      position: absolute;
      z-index: -1;
      top: 0;
      width: 100%;
      height: 100%;
      // background: url("https://source.unsplash.com/random/1");
      // background-position: center;
      // background-size: cover;
      // filter: opacity(75%);
      // filter: blur(10px);
      // backdrop-filter: blur(5px);
      border-radius: $radius $radius 0px 0px;
      .blur {
        // background: rgba(
        //   255,
        //   255,
        //   255,
        //   0.2
        // ); // Make sure this color has an opacity of less than 1
        // backdrop-filter: blur(0.5rem); // This be the blur

        height: 100%;
        width: 100%;
        // border: 1px solid $gray-soft;
        // border-bottom: none;
        border-radius: $radius $radius 0px 0px;
      }
    }
  }
  .card__description {
    height: $description__height;
    padding: 0.5rem 1rem;
    display: grid;
    align-items: center;
    grid-template-rows: repeat(3, 1fr);
    .card__description__title {
      @extend %text__ellipsis;
    }
  }
}

.card-shadow {
  box-shadow: 0.5rem 0.5rem 1rem 0px rgb(38, 38, 38, 0.05);
  &:hover {
    box-shadow: 0.5rem 0.5rem 1rem 0px rgb(38, 38, 38, 0.2);
  }
}

.auction-info__time {
  border: 2px solid $gray-soft;
  border-radius: $radius $radius $radius $radius;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auction-info__status {
  position: absolute;
  width: 100%;
  height: 3.5rem;
  border-radius: 0 0 0.64rem 0.64rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-radius {
  border-radius: $auction-info__radius;
}
</style>
