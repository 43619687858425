<template>
  <div class="relative w-full h-full">
    <normal-card
      :id="id"
      :img="img"
      :title="title"
      :price="price"
      :symbol="symbol"
    />
    <div
      class="auction-info__time absolute z-10 w-40 lg:w-44 h-8 bottom-36 right-0 card-radius"
    >
      <span v-html="htmlCounter"></span>
    </div>
  </div>
</template>

<script>
import NormalCard from "@/components/card/NormalCard.vue";

export default {
  props: {
    orderState: {
      type: Number,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    symbol: {
      type: String,
      required: true,
    },
    end_at: {
      type: String,
      required: true,
    },
  },

  data: function() {
    return {
      interval: {},
      htmlCounter: "",
    };
  },

  components: {
    NormalCard,
  },

  mounted() {
    this.setupInterval();
  },

  methods: {
    remainTime(endTime) {
      const total = Date.parse(endTime) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));

      return {
        total,
        days,
        hours,
        minutes,
        seconds,
      };
    },
    setupInterval() {
      const t = this.remainTime(this.end_at);

      if (t.total < 0 || this.orderState === 3) {
        this.htmlCounter =
          "<span class='text-xs lg:text-sm parula__text-gray'>Auction Ended</span>";
        return;
      }
      this.interval = setInterval(() => {
        if (this.end_at) {
          const t = this.remainTime(this.end_at);
          //
          const html = `
            <span class="-mr-1 text-xs lg:text-sm parula__text-gray">${
              t.days
            }</span>
            <span class="text-xs lg:text-sm parula__text-gray">D</span>
            <span class="-mr-1 text-xs lg:text-sm parula__text-gray">${(
              "00" + t.hours
            ).slice(-2)}</span>
            <span class="text-xs lg:text-sm parula__text-gray">H</span>
            <span class="-mr-1 text-xs lg:text-sm parula__text-gray">${(
              "00" + t.minutes
            ).slice(-2)}</span>
            <span class="text-xs lg:text-sm parula__text-gray">M</span>
            <span class="-mr-1 text-xs lg:text-sm parula__text-gray">${(
              "00" + t.seconds
            ).slice(-2)}</span>
            <span class="text-xs lg:text-sm parula__text-gray">S</span>
          `;
          this.htmlCounter = html;
        } else {
          this.htmlCounter = "";
        }
      }, 1000);
    },
    formatDate(d) {
      const now = Date();
      const target = new Date(d);
      const secondsLeft = target - now;
      const days = Math.floor(secondsLeft / 24 / 60 / 60);
      const hoursLeft = Math.floor(secondsLeft - days * 86400);
      const hours = Math.floor(hoursLeft / 3600);
      const minutesLeft = Math.floor(hoursLeft - hours * 3600);
      const minutes = Math.floor(minutesLeft / 60);
      const seconds = secondsLeft % 60;

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        second: seconds,
        date: target,
      };
    },
  },

  unmounted() {
    console.log("unmounted !");
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_text.scss";
@import "@/scss/_variables.scss";

.auction-info__time {
  border: 2px solid $gray-soft;
  border-radius: $radius $radius $radius $radius;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-radius {
  border-radius: $auction-info__radius;
}
</style>
